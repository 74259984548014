<template>
  <v-list
    dense
    nav
  >
    <slot />
  </v-list>
</template>

<script>
/**
 * NavigationDrawerList component
 */
export default {
  name: 'NavigationDrawerList',
};
</script>
